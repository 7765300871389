import * as React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SocialIcons from "./socialIcons"

const SectionWhatsNext = ({
  what_s_next_title,
  what_s_next_paragraph,
  what_s_next_background,
  what_s_next_background_desktop,
  what_s_next_image,
}) => {
  return (
    <div className="grid">
      {what_s_next_background && (
        <div className="md:hidden" css={cssWhatsNextArea}>
          <GatsbyImage
            image={getImage(what_s_next_background)}
            alt=""
            className=" rounded-xl mx-auto h-full text-center"
          />
        </div>
      )}
      {what_s_next_background_desktop && (
        <div className="hidden md:block md:w-full" css={cssWhatsNextArea}>
          <GatsbyImage
            image={getImage(what_s_next_background_desktop)}
            alt=""
            className="md:w-full  mx-auto text-center min-h-full"
          />
        </div>
      )}
      <div
        className="container relative mx-auto lg:px-32"
        css={cssWhatsNextArea}
      >
        <div className=" flex flex-col items-center justify-center h-full md:flex-row  ">
          <div className="flex-1 mb-5">
            <div
              className="font-sans  text-4xl font-extrabold text-white mx-auto mb-5 text-center px-10 pt-24  md:text-left  lg:text-6xl"
              dangerouslySetInnerHTML={{
                __html: what_s_next_title.html,
              }}
            />
            <div
              className="text-base text-white text-center px-10 max-w-2xl md:text-left lg:text-lg"
              dangerouslySetInnerHTML={{
                __html: what_s_next_paragraph.html,
              }}
            />
            <div className="flex justify-center content-center mt-5   md:justify-start md:pl-10">
              <SocialIcons isDark={false} />
            </div>
          </div>
          <div className="flex items-end flex-1 md:self-end md:text-center">
            <GatsbyImage
              css={cssNextImage}
              image={getImage(what_s_next_image)}
              alt={what_s_next_image.alt || ""}
              className="mx-auto mt-10 text-center "
            />
          </div>
        </div>
      </div>
    </div>
  )
}
const cssWhatsNextArea = css`
  grid-area: 1/1;
`

const cssNextImage = css`
  max-width: 250px;
  @media (min-width: 768px) {
    max-width: 32rem;
  }
`

SectionWhatsNext.propTypes = {
  siteTitle: PropTypes.string,
  what_s_next_title: PropTypes.object,
  what_s_next_paragraph: PropTypes.object,
  what_s_next_background: PropTypes.object,
  what_s_next_background_desktop: PropTypes.object,
  what_s_next_image: PropTypes.object,
}

export default SectionWhatsNext
