import * as React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import { GatsbyImage } from "gatsby-plugin-image"
import StoreButtons from "./storeButtons"

const SectionSidedContent = ({
  image,
  title,
  paragraph,
  image_side,
  display_app_store_buttons,
  globals,
}) => {
  return (
    <div className="mt-5   md:mt-10 md:flex md:items-center">
      <div
        className={`text-center lg:px-16 ${image_side ? "order-2" : "order-1"}`}
      >
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt || ""}
          className="rounded-xl"
        />
      </div>
      <div
        className={`text-center lg:px-16 ${image_side ? "order-1" : "order-2"}`}
      >
        <div
          className="font-sans text-4xl text-left font-extrabold mb-5 lg:text-6xl "
          css={image_side ? cssYellowTitle : cssBlueTitle}
          dangerouslySetInnerHTML={{ __html: title.html }}
        />
        <div
          className="text-base text-left text-pocket-text max-w-2xl  lg:text-lg lg:my-10"
          css={cssParagraph}
          dangerouslySetInnerHTML={{ __html: paragraph.html }}
        />
        {display_app_store_buttons && (
          <div className="flex mt-5 justify-center">
            <StoreButtons globals={globals} />
          </div>
        )}
      </div>
    </div>
  )
}

const cssBlueTitle = css`
  & strong {
    color: var(--pocket-blue);
  }
`
const cssYellowTitle = css`
  & strong {
    color: var(--pocket-yellow);
  }
`
const cssParagraph = css`
  font-size: inherit;
`

SectionSidedContent.propTypes = {
  image: PropTypes.object,
  title: PropTypes.object,
  paragraph: PropTypes.object,
  image_side: PropTypes.bool,
  display_app_store_buttons: PropTypes.bool,
  globals: PropTypes.object.isRequired,
}

export default SectionSidedContent
