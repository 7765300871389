import * as React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player/file"
import arrow_scroll from "../images/icons/arrow-scroll.svg"

const Hero = ({ hero, heroDesktop, heroVideo, globals }) => {
  return (
    <section className="text-center container mx-auto px-8 lg:px-32 lg:mb-16">
      <div className="m-auto relative h-44 sm:h-80 md:h-[432px] ">
        {heroVideo && (
          <div css={cssPlayer} className="absolute top-0 left-0 w-full h-full ">
            <ReactPlayer
              url={heroVideo.url}
              loop={true}
              playing={true}
              controls={false}
              volume={0}
              muted={true}
              width={"100%"}
              height={"100%"}
              playsinline={true}
            />
          </div>
        )}

        <div className="hidden absolute bottom-0 w-full lg:block font-sans uppercase text-white font-extrabold -translate-y-12 ">
          Scroll
          <span>
            <img
              src={arrow_scroll}
              alt=""
              className={`animate-bounce m-auto pt-2 `}
            />
          </span>
        </div>
        {/* {homepage.data.hero && (
            <GatsbyImage
              image={getImage(homepage.data.hero)}
              alt={homepage.data.hero.alt}
              className=" rounded-xl mx-auto text-center"
            />
          )} */}
      </div>
      {/* <div className="hidden md:block">
          {homepage.data.hero_desktop && (
            <GatsbyImage
              image={getImage(homepage.data.hero_desktop)}
              alt={homepage.data.hero.alt || ""}
              className="rounded-[2rem] mx-auto text-center"
            />
          )}
        </div> */}
    </section>
  )
}

const cssPlayer = css`
  video {
    border-radius: 24px;
    object-fit: cover;
  }
`

Hero.propTypes = {
  image: PropTypes.object,
  title: PropTypes.object,
  paragraph: PropTypes.object,
  image_side: PropTypes.bool,
  display_app_store_buttons: PropTypes.bool,
  globals: PropTypes.object.isRequired,
}

export default Hero
