import * as React from "react"
import { graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"

import { langs } from "../../prismic-configuration.js"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import SectionSidedContent from "../components/sectionSidedContent"
import SectionWhatsNext from "../components/sectionWhatsNext"
import SectionBlog from "../components/sectionBlog"

const IndexPage = ({ data, pageContext }) => {
  if (!data) return null
  const homepage = data.prismicHomepage || {}
  return (
    <Layout lang={pageContext.lang} globals={data.prismicGlobals}>
      <Seo title="Home" />
      <Hero
        hero={homepage.data.hero}
        heroDesktop={homepage.data.hero_desktop}
        heroVideo={homepage.data.hero_video}
      />
      <section className="container mx-auto px-8 mt-10   lg:px-32 lg:my-24">
        {homepage.data.features.map((feature, i) => {
          return (
            <SectionSidedContent
              key={i}
              image={feature.feature_image}
              title={feature.title}
              paragraph={feature.paragraph}
              image_side={feature.image_side}
              display_app_store_buttons={feature.display_app_store_buttons}
              globals={data.prismicGlobals}
            />
          )
        })}
      </section>
      {data.allPrismicBlogPost.edges && (
        <div className="mt-10   lg:my-32 lg:mb-16">
          <SectionBlog
            posts={data.allPrismicBlogPost.edges}
            globals={data.prismicGlobals}
            lang={pageContext.lang}
          />
        </div>
      )}
      <section className="mt-10">
        <SectionWhatsNext
          what_s_next_title={homepage.data.what_s_next_title}
          what_s_next_paragraph={homepage.data.what_s_next_paragraph}
          what_s_next_background={homepage.data.what_s_next_background}
          what_s_next_background_desktop={
            homepage.data.what_s_next_background_desktop
          }
          what_s_next_image={homepage.data.what_s_next_image}
          globals={data.prismicGlobals}
        />
      </section>
    </Layout>
  )
}

const cssIframe = css`
  iframe {
    height: 260px;
    width: 100%;
    @media (min-width: 768px) {
      height: 350px;
    }
    @media (min-width: 1280px) {
      height: 450px;
    }
  }
`

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        seo_title
        seo_description
        seo_og_image {
          url
        }
        hero {
          gatsbyImageData
          alt
        }
        hero_desktop {
          gatsbyImageData
          alt
        }
        hero_video {
          link_type
          url
          size
          type
        }
        features {
          feature_image {
            gatsbyImageData
            alt
          }
          title {
            text
            html
          }
          paragraph {
            text
            html
          }
          image_side
          display_app_store_buttons
        }
        what_s_next_title {
          text
          html
        }
        what_s_next_paragraph {
          text
          html
        }
        what_s_next_background {
          gatsbyImageData
          alt
        }
        what_s_next_background_desktop {
          gatsbyImageData
          alt
        }
        what_s_next_image {
          gatsbyImageData
          alt
        }
      }
    }

    allPrismicBlogPost(
      limit: 3
      filter: {
        lang: { eq: $lang }
        data: {
          publishing_order: { gt: "1" }
          displayed_on: { ne: "webview only" }
        }
      }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          lang
          url
          data {
            type
            cover {
              alt
              gatsbyImageData
            }
            structured_text {
              text
            }
            title {
              text
            }
            category {
              document {
                ... on PrismicArticleCategory {
                  data {
                    category_name
                    category_color
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicGlobals(lang: { eq: $lang }) {
      data {
        follow_us
        read_more
        view_all
        footer_title
        footer_subtitle
        copyright
        store {
          link
          store_id
        }
        social {
          link
          social_id
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ data, pageContext }) => {
  const { siteUrl } = data.site.siteMetadata
  return (
    <Seo
      title={data.prismicHomepage.data.seo_title}
      description={data.prismicHomepage.data.seo_description}
    >
      {data.prismicHomepage.data.seo_og_image && (
        <meta
          property="og:image"
          content={data.prismicHomepage.data.seo_og_image?.url}
        />
      )}
      {langs.map((lang, i) => (
        <link
          rel="alternate"
          key={i}
          hrefLang={lang.slice(0, 2)}
          href={i === 0 ? siteUrl : siteUrl + "/" + lang}
        />
      ))}
    </Seo>
  )
}

export default IndexPage
