import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import CardBlogPost from "./cardBlogPost"
import iconArrowRight from "../images/icons/arrow_right.svg"

const SectionBlog = ({ posts = [], globals, lang }) => {
  return (
    <div className="container relative mx-auto px-8 lg:px-32">
      <CardBlogPost post={posts[0]?.node} isLarge={true} globals={globals} />
      <div className="md:flex md:content-center md:justify-between">
        {posts.slice(1, 3).map((post, i) => (
          <div key={i} className="md:mx-3 md:w-2/5">
            <CardBlogPost post={post.node} isLarge={false} globals={globals} />
          </div>
        ))}
        <div className="relative w-fit min-w-fit m-auto bg-pocket-yellow pr-10 rounded-xl shadow-lg shadow-gray-100   md:w-fit md:ml-5 ">
          <Link to={lang === "en-us" ? "/blog" : `/${lang}/blog`}>
            <div className="bg-white text-pocket-yellow px-2 py-8 -ml-3 rounded-xl">
              <span className="text-base font-sans uppercase font-extrabold px-5">
                {globals.data.view_all}
              </span>
            </div>
            <img
              src={iconArrowRight}
              className="absolute right-[-3px] top-5"
              alt=""
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

SectionBlog.propTypes = {
  posts: PropTypes.array,
  globals: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
}

export default SectionBlog
